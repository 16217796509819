import React from 'react';

const Logo = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-message-circle w-12 h-12"
        >
            <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
            <text x="7" y="17" fontSize="15" fill="currentColor" fontWeight="normal">
                S
            </text>
        </svg>
    );
};

export default Logo;
