// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAEgvSSpJzE-pvGwZOw0vx9vDzypgv3lYY",
    authDomain: "speakwai-de698.firebaseapp.com",
    projectId: "speakwai-de698",
    storageBucket: "speakwai-de698.appspot.com",
    messagingSenderId: "37989165551",
    appId: "1:37989165551:web:00e97e0462bfe863502e1a",
    measurementId: "G-ZWSWVV7XKF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);