import React, { useState }from 'react';
import './App.css';
import LogoSvg from "./components/LogoSvg";
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

function App() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'waitlist'), {
                email: email,
                timestamp: new Date()
            });
            setMessage('Thank you! We\'ll notify you when we launch.');
            setEmail('');
        } catch (error) {
            console.error("Error adding document: ", error);
            setMessage('An error occurred. Please try again.');
        }
    };

    const handleClick = () => {
        const targetSection = document.getElementById('waitlist-section');

        if (targetSection) {
            targetSection.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    return (
        <div className="App">
            <header className="header">
                <div className="nav-items max-w-7xl mx-auto">
                    <div className="logo items-center" style={{ display: 'inline-flex', alignItems: 'center' }}><LogoSvg/>PEAKWAI</div>
                    <button className="waitlist-button" onClick={handleClick}>Join the Waitlist</button>
                </div>
            </header>

            <main className="main-content">
                <div className="container mx-auto px-6 py-12 md:py-24">
                    <div className="max-w-5xl mx-auto">
                        <h1>
                            Revolutionary Language Learning Platform <span className="highlight">Coming Soon!</span>
                        </h1>
                        <p className="text">
                            Get ready to transform your language learning experience with <b className="highlight">SPEAKWAI (Speak with AI)</b> . Our AI-powered platform will help you master multiple languages through engaging, personalized conversations.
                        </p>

                        <section className="waitlist-section" id="waitlist-section">
                            <h2 className="move-up-down">Join the Waitlist</h2>
                            <b className='limited-spots'>🚀 Limited spots available for launch!</b>
                            <p><b>Join the waitlist now and get an exclusive discount when we launch!</b></p>
                            <form onSubmit={handleSubmit} className="email-form">
                                <div className="input-wrapper">
                                    <div className="envelope-icon">
                                        <div className="envelope-flap"></div>
                                    </div>
                                    <label htmlFor="email" className="visually-hidden">Email address</label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email"
                                        required
                                        aria-required="true"
                                    />
                                </div>
                                <button type="submit">Notify Me</button>
                            </form>
                            {message && <b className="message" aria-live="polite">{message}</b>}
                        </section>

                        <section>
                            <div className="screenshot">
                                <svg viewBox="0 0 800 400" xmlns="http://www.w3.org/2000/svg" className="move-up-down">
                                    <defs>
                                        <linearGradient id="bg-gradient" x1="0" y1="0" x2="800" y2="400">
                                            <stop offset="0%" stopColor="#ffffff"/>
                                            <stop offset="100%" stopColor="#f5f5f5"/>
                                        </linearGradient>
                                        <linearGradient id="bubble-gradient" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="0%" stopColor="#4a4a4a"/>
                                            <stop offset="100%" stopColor="#363636"/>
                                        </linearGradient>
                                        <linearGradient id="text-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stopColor="gray" />
                                            <stop offset="50%" stopColor="dimgray;" />
                                            <stop offset="100%" stopColor="black" />
                                        </linearGradient>
                                    </defs>

                                    <rect width="800" height="400" fill="url(#bg-gradient)"/>

                                    <circle cx="400" cy="200" r="150" fill="none" stroke="#404040" strokeWidth="1" opacity="0.1"/>
                                    <circle cx="400" cy="200" r="120" fill="none" stroke="#404040" strokeWidth="1" opacity="0.2"/>
                                    <circle cx="400" cy="200" r="90" fill="none" stroke="#404040" strokeWidth="1" opacity="0.3"/>

                                    <g transform="translate(325, 20)">
                                        <LogoSvg/>
                                        <text x="38" y="28" fontFamily="Arial" fontSize="22" fontWeight="bold" fill="url(#text-gradient)">PEAKWAI</text>
                                    </g>

                                    <g transform="translate(100, 150)">
                                        <rect x="0" y="0" width="140" height="50" rx="25" fill="url(#bubble-gradient)" opacity="0.9"/>
                                        <text x="20" y="30" fontFamily="Arial" fontSize="12" fill="white">Hello! Let's practice!</text>
                                    </g>

                                    <g transform="translate(150, 220)">
                                        <rect x="0" y="0" width="120" height="50" rx="25" fill="url(#bubble-gradient)" opacity="0.7"/>
                                        <text x="20" y="30" fontFamily="Arial" fontSize="12" fill="white">¡Hola! 你好!</text>
                                    </g>

                                    <g transform="translate(550, 150)">
                                        <rect x="0" y="0" width="150" height="50" rx="25" fill="url(#bubble-gradient)" opacity="0.8"/>
                                        <text x="20" y="30" fontFamily="Arial" fontSize="12" fill="white">Practice anytime!</text>
                                    </g>

                                    <g transform="translate(520, 220)">
                                        <rect x="0" y="0" width="130" height="50" rx="25" fill="url(#bubble-gradient)" opacity="0.6"/>
                                        <text x="20" y="30" fontFamily="Arial" fontSize="12" fill="white">Bonjour! こんにちは!</text>
                                    </g>

                                    <g transform="translate(300, 120)">
                                        <rect x="50" y="0" width="100" height="160" rx="10" fill="white" stroke="#404040" strokeWidth="2"/>

                                        <rect x="65" y="20" width="70" height="8" rx="4" fill="#4a4a4a" opacity="0.8"/>
                                        <rect x="65" y="40" width="50" height="8" rx="4" fill="#4a4a4a" opacity="0.6"/>
                                        <rect x="65" y="60" width="60" height="8" rx="4" fill="#4a4a4a" opacity="0.7"/>

                                        <circle cx="100" cy="100" r="15" fill="#2c2c2c"/>
                                        <text x="93" y="105" fontFamily="Arial" fontSize="12" fill="white">AI</text>
                                    </g>

                                    <g transform="translate(200, 300)">

                                        {/*24/7*/}
                                        <circle cx="120" cy="0" r="20" fill="none" stroke="#404040" strokeWidth="2"/>
                                        <text x="110" y="5" fontFamily="Arial" fontSize="10" fontWeight="bold" fill="#404040">24/7</text>

                                        {/*Graphe*/}
                                        <path d="M 250 30 L 250 0" fill="none" stroke="#404040" strokeWidth="2"/>
                                        <path d="M 250 30 L 290 30" fill="none" stroke="#404040" strokeWidth="2"/>
                                        <rect x="275" y="5" width="6" height="25" fill="#404040"/>
                                        <rect x="265" y="10" width="6" height="20" fill="#404040"/>
                                        <rect x="255" y="15" width="6" height="15" fill="#404040"/>
                                    </g>

                                    <g transform="translate(0, 0)" fontFamily="Arial" fontSize="14" fill="#4a4a4a" opacity="0.5">
                                        {/*SPEAKER*/}
                                        <rect x="290" y="120" width="30" height="30" rx="5" fill="none" stroke="#404040" strokeWidth="2"/>
                                        <path d="M 295 130 L 300 125 L 300 145 L 295 140 Z" fill="#404040"/>
                                        <circle cx="305" cy="135" r="6" fill="none" stroke="#404040" strokeWidth="1.5"/>
                                        <circle cx="305" cy="135" r="4" fill="none" stroke="#404040" strokeWidth="1"/>
                                        <circle cx="305" cy="135" r="2" fill="#404040"/>
                                        <path d="M 312 125 C 314 128, 316 130, 316 135 C 316 140, 314 142, 312 142"
                                              fill="none"
                                              stroke="#404040"
                                              strokeWidth="1.5"/>
                                        <path d="M 314 125 C 317 125, 319 130, 319 135 C 319 140, 317 145, 314 145"
                                              fill="none"
                                              stroke="#404040"
                                              strokeWidth="1.5"/>
                                        {/*MICRO*/}
                                        <rect x="500" y="140" width="30" height="30" rx="5" fill="none" stroke="#404040" strokeWidth="2"/>
                                        <path d="M 510 150 L 510 160 C 510 163, 520 163, 520 160 L 520 150 C 520 147, 510 147, 510 150"
                                              fill="#000000"
                                              stroke="#404040"
                                              strokeWidth="1.5"/>
                                        <path d="M 515 160 L 515 165"
                                              stroke="#404040"
                                              strokeWidth="1.5"/>
                                        <path d="M 512 165 L 518 165"
                                              stroke="#404040"
                                              strokeWidth="1.5"/>

                                        <text x="170" y="95" fill="#000000">EN</text>
                                        <text x="610" y="95" fill="#000000">FR</text>
                                        <text x="190" y="320" fill="#000000">ES</text>
                                        <text x="600" y="320" fill="#000000">JP</text>
                                    </g>

                                    <g stroke="#e0e0e0" strokeWidth="1">
                                        <path d="M 200 100 C 250 150, 350 150, 400 200" fill="none"/>
                                        <path d="M 600 100 C 550 150, 450 150, 400 200" fill="none"/>
                                        <path d="M 200 300 C 250 250, 350 250, 400 200" fill="none"/>
                                        <path d="M 600 300 C 550 250, 450 250, 400 200" fill="none"/>
                                    </g>
                                </svg>
                            </div>
                        </section>

                        <section className="features-section">
                            <div className="feature-card">
                                <div className="feature-icon">🤖</div>
                                <p className="feature-text">AI-powered conversation partner available 24/7</p>
                            </div>
                            <div className="feature-card">
                                <div className="feature-icon">🌍</div>
                                <p className="feature-text">Learn multiple languages on one platform</p>
                            </div>
                            <div className="feature-card">
                                <div className="feature-icon">🎯</div>
                                <p className="feature-text">Personalized learning experience</p>
                            </div>
                            <div className="feature-card">
                                <div className="feature-icon">🔊</div>
                                <p className="feature-text">Practice speaking and listening skills</p>
                            </div>
                            <div className="feature-card">
                                <div className="feature-icon">📊</div>
                                <p className="feature-text">Track your progress with detailed insights</p>
                            </div>
                            <div className="feature-card">
                                <div className="feature-icon">📱</div>
                                <p className="feature-text">Access on any device, anytime, anywhere</p>
                            </div>
                        </section>

                    </div>
                </div>
            </main>

            <footer className="footer">
                © 2024 SPEAKWAI. All rights reserved.
            </footer>
        </div>
    );
}

export default App;
